<script setup lang="ts">
  import { formatText } from '~/utils/format-text'

  const props = withDefaults(defineProps<{
    standout?: boolean
    title?: string
    description?: string
    readMoreLink?: string
    readMoreText?: string
    headerContainerWidth?: 'text' | 'full'
  }>(), { standout: false, headerContainerWidth: 'full' })

  const hasHeader = props.title || props.description || props.readMoreLink
</script>

<template>
  <section :class="['app-section', { 'app-section--standout': standout }]">
    <app-container
      v-if="hasHeader"
      :text="headerContainerWidth === 'text'"
      class="app-section__header-container">
      <header
        v-if="title || readMoreLink"
        class="app-section__header">
        <h2
          class="app-section__title"
          v-if="title">
          {{ formatText(title) }}
        </h2>
        <nuxt-link
          v-if="readMoreLink"
          :to="readMoreLink"
          class="app-section__more u-white-space--nowrap">
          {{ readMoreText || 'Více' }} >
        </nuxt-link>
      </header>
      <p
        v-if="description"
        class="app-section__description">
        {{ formatText(description) }}
      </p>
    </app-container>
    <div :class="['app-section__content', { 'app-section__content--standout': standout }]">
      <slot />
    </div>
  </section>
</template>

<style scoped>
  .app-section--standout {
    position: relative;

    &:deep(.app-container__content) {
      background: var(--c-background-light);
      padding-left: var(--gap-400);
      padding-right: var(--gap-400);
    }

    & .app-section__header-container:deep(.app-container__content) {
      padding-top: var(--gap-400);
    }

    & .app-section__content :deep(.app-container:last-child) {
      & .app-container__content {
        padding-bottom: var(--gap-400);
      }
    }
  }

  .app-section__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: var(--gap-400);
    & h2 {
      margin-bottom: 0;
      margin-right: var(--gap-400);
    }
  }

  .app-section__more {
    margin-left: auto;
  }

  .app-section__description {
    margin-bottom: var(--gap-500);
  }
</style>
